import Fieldset from '../../elements/Fieldset'
import FieldsetSelect from '../../elements/FieldsetSelect'
import MultibuyCalculator from '../../elements/MultibuyCalculator'

const DealDetailsForm = ({ handleInputChange, dealType, stackingLimit, stackingDiscount, currentDeal }) => {
  return (
    <form>
      <section>
        <Fieldset
          displayName='Name'
          fieldId='name'
          fieldType='text'
          fieldHelp='For internal reference only'
          value={currentDeal.name}
          changeEvent={handleInputChange}
        />
        <Fieldset
          displayName='Description'
          fieldId='description'
          fieldType='text'
          fieldHelp='Displayed to customers on collection and product pages'
          value={currentDeal.description}
          changeEvent={handleInputChange}
        />
      </section>
      <section>
        <FieldsetSelect
          displayName='Type'
          fieldId='type'
          value={currentDeal.friendly_type}
          fieldOptions={['Buy 1 get 1 free', '3 for 2', 'Buy 1 get 1 half price', 'Multibuy']}
          changeEvent={handleInputChange}
        />
        {dealType === 'stacking' && (
          <Fieldset
            displayName='Stacking Discount'
            fieldId='stacking_discount'
            fieldType='number'
            fieldMin='1'
            fieldMax='20'
            step='0.5'
            fieldHelp='Percentage steps of the multibuy discount'
            value={Number((currentDeal.discount * 100).toFixed(1))}
            changeEvent={handleInputChange}
          />
        )}
        {dealType === 'stacking' && (
          <Fieldset
            displayName='Stacking Limit'
            fieldId='stacking_limit'
            fieldType='number'
            fieldMin='1'
            fieldMax='20'
            step='1'
            fieldHelp='Maximum multiplier on multibuy deal'
            value={currentDeal.stacking_limit}
            changeEvent={handleInputChange}
          />
        )}
        {dealType === 'stacking' && <MultibuyCalculator stackingLimit={stackingLimit} stackingDiscount={stackingDiscount} />}
      </section>
    </form>
  )
}

export default DealDetailsForm
