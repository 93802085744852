import { useState, useEffect, useMemo } from 'react'
import debounce from 'lodash/debounce'
import Product from '../../elements/Product'
import Collection from '../../elements/Collection'
import { ReactComponent as Spinner } from '../../elements/spinner.svg'

const ProductSelection = ({
  doSearch,
  searchResults,
  productOrCollection,
  switchProductOrCollection,
  handleAddProduct,
  handleAddCollection,
  currentProducts,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(true)

  // remove spinner when search results are returned
  useEffect(() => {
    setLoading(false)
  }, [searchResults, currentProducts])

  // clear search box on mode change
  // remove spinner when search results are returned
  useEffect(() => {
    setSearchTerm('')
  }, [productOrCollection])

  // debounce search box
  const debounceHandler = useMemo(
    () =>
      debounce(value => {
        setLoading(true)
        const queryTerm = `${productOrCollection}_search`
        doSearch(value, queryTerm)
      }, 1000),
    [productOrCollection, doSearch]
  )

  // on search input, trigger search box debouncing
  const handleInputChange = e => {
    const value = e.target.value
    setSearchTerm(value)
    debounceHandler(value)
  }

  // set loading when adding a collection
  const addCollection = collection => {
    setLoading(true)
    handleAddCollection(collection)
  }

  return (
    <section className='products-list'>
      <div className='tabs'>
        <span onClick={() => switchProductOrCollection('products')} className={productOrCollection === 'products' ? 'active' : undefined}>
          Add Products
        </span>
        <span
          onClick={() => switchProductOrCollection('collection')}
          className={productOrCollection === 'collection' ? 'active' : undefined}>
          Add Collection
        </span>
      </div>
      <p className='help-text'>
        Click {productOrCollection === 'products' ? 'a product to add it to the deal' : 'to add entire collection to the deal'}
      </p>
      <input type='search' placeholder='Search' value={searchTerm} onChange={handleInputChange} />
      {productOrCollection === 'products' ? (
        <div className={`products-list-container ${loading ? 'loading' : ''}`}>
          {loading ? (
            <Spinner />
          ) : searchResults && searchResults.length > 0 ? (
            searchResults.map(result => (
              <Product
                key={result.id}
                handleProductClick={handleAddProduct}
                product={result}
                inDeal={currentProducts.find(existingProduct => existingProduct.id === result.id) ? true : false}
              />
            ))
          ) : (
            <p className='help-text'>No results</p>
          )}
        </div>
      ) : (
        <div className={`collection-list-container ${loading ? 'loading' : ''}`}>
          {loading ? (
            <Spinner />
          ) : searchResults && searchResults.length > 0 ? (
            searchResults.map(result => (
              <Collection key={result.id} handleCollectionClick={() => addCollection(result)} collection={result} />
            ))
          ) : (
            <p className='help-text'>No results</p>
          )}
        </div>
      )}
    </section>
  )
}

export default ProductSelection
